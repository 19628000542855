<script setup lang="ts">
import CounterItem from '@/Components/Counter/CounterItem.vue';
import { useI18n } from 'vue-i18n';

defineProps({
    animationPaused: Boolean,
});

const { t } = useI18n();

const theFormat = (number: number) => {
    return number.toFixed(1);
};
</script>

<template>
    <div class="relative grid items-center gap-6 md:grid-cols-3">
        <CounterItem
            :countTo="449"
            :durationCounter="3"
            :delayCounter="1"
            :label="t('Active fl@xrs')"
            :animationPaused="animationPaused"
            class=""
        />

        <CounterItem
            :countTo="18"
            :durationCounter="3"
            :delayCounter="1"
            :label="t('Supermarkets')"
            :animationPaused="animationPaused"
        />

        <CounterItem
            :countTo="0"
            customOutput="4.5 / 5"
            :durationCounter="0"
            :delayCounter="1"
            :format="theFormat"
            :label="t('Average reviews from our users')"
            :animationPaused="animationPaused"
        />
    </div>
</template>
