<script setup>
// Components
import Img from '@/Components/Img.vue';
import { computed, ref } from 'vue';

import Button from '@/Components/Jetstream/Button.vue';
//
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/20/solid';

import EmployerDesktopStep1 from '../../../img/explanationStepper/employer-desktop-screen-step-1.png?metadata&webp';
import EmployerDesktopStep1En
    from '../../../img/explanationStepper/employer-desktop-screen-step-1-en.png?metadata&webp';
import EmployerDesktopStep2 from '../../../img/explanationStepper/employer-desktop-screen-step-2.png?metadata&webp';
import EmployerDesktopStep2En
    from '../../../img/explanationStepper/employer-desktop-screen-step-2-en.png?metadata&webp';
import EmployerDesktopStep3 from '../../../img/explanationStepper/employer-desktop-screen-step-3.png?metadata&webp';
import EmployerDesktopStep3En
    from '../../../img/explanationStepper/employer-desktop-screen-step-3-en.png?metadata&webp';
import EmployerDesktopStep4 from '../../../img/explanationStepper/employer-desktop-screen-step-4.png?metadata&webp';
import EmployerDesktopStep4En
    from '../../../img/explanationStepper/employer-desktop-screen-step-4-en.png?metadata&webp';

import EmployerPhoneStep1 from '../../../img/explanationStepper/employer-phone-screen-step-1.png?metadata&webp';
import EmployerPhoneStep1En from '../../../img/explanationStepper/employer-phone-screen-step-1-en.png?metadata&webp';
import EmployerPhoneStep2 from '../../../img/explanationStepper/employer-phone-screen-step-2.png?metadata&webp';
import EmployerPhoneStep2En from '../../../img/explanationStepper/employer-phone-screen-step-2-en.png?metadata&webp';
import EmployerPhoneStep3 from '../../../img/explanationStepper/employer-phone-screen-step-3.png?metadata&webp';
import EmployerPhoneStep3En from '../../../img/explanationStepper/employer-phone-screen-step-3-en.png?metadata&webp';
import EmployerPhoneStep4 from '../../../img/explanationStepper/employer-phone-screen-step-4.png?metadata&webp';
import EmployerPhoneStep4En from '../../../img/explanationStepper/employer-phone-screen-step-4-en.png?metadata&webp';


import EmployeePhoneStep1 from '../../../img/explanationStepper/employee-phone-screen-step-1.png?metadata&webp';
import EmployeePhoneStep1En from '../../../img/explanationStepper/employee-phone-screen-step-1-en.png?metadata&webp';
import EmployeePhoneStep2 from '../../../img/explanationStepper/employee-phone-screen-step-2.png?metadata&webp';
import EmployeePhoneStep2En from '../../../img/explanationStepper/employee-phone-screen-step-2-en.png?metadata&webp';
import EmployeePhoneStep3 from '../../../img/explanationStepper/employee-phone-screen-step-3.png?metadata&webp';
import EmployeePhoneStep3En from '../../../img/explanationStepper/employee-phone-screen-step-3-en.png?metadata&webp';
import EmployeePhoneStep4 from '../../../img/explanationStepper/employee-phone-screen-step-4.png?metadata&webp';
import EmployeePhoneStep4En from '../../../img/explanationStepper/employee-phone-screen-step-4-en.png?metadata&webp';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const stepsEmployer = computed(() => [
    {
        image: EmployerDesktopStep1,
        imageEn: EmployerDesktopStep1En,
        imagePhone: EmployerPhoneStep1,
        imagePhoneEn: EmployerPhoneStep1En,
        title: props.block.content.steps[0]?.title,
        description: props.block.content.steps[0]?.description,
    },
    {
        image: EmployerDesktopStep2,
        imageEn: EmployerDesktopStep2En,
        imagePhone: EmployerPhoneStep2,
        imagePhoneEn: EmployerPhoneStep2En,
        title: props.block.content.steps[1]?.title,
        description: props.block.content.steps[1]?.description,
    },
    {
        image: EmployerDesktopStep3,
        imageEn: EmployerDesktopStep3En,
        imagePhone: EmployerPhoneStep3,
        imagePhoneEn: EmployerPhoneStep3En,
        title: props.block.content.steps[2]?.title,
        description: props.block.content.steps[2]?.description,
    },
    {
        image: EmployerDesktopStep4,
        imageEn: EmployerDesktopStep4En,
        imagePhone: EmployerPhoneStep4,
        imagePhoneEn: EmployerPhoneStep4En,
        title: props.block.content.steps[3]?.title,
        description: props.block.content.steps[3]?.description,
    },
]);

const stepsEmployee = computed(() => [
    {
        image: EmployeePhoneStep1,
        imageEn: EmployeePhoneStep1En,
        imagePhone: EmployeePhoneStep1,
        imagePhoneEn: EmployeePhoneStep1En,
        title: props.block.content.steps[0]?.title,
        description: props.block.content.steps[0]?.description,
    },
    {
        image: EmployeePhoneStep2,
        imageEn: EmployeePhoneStep2En,
        imagePhone: EmployeePhoneStep2,
        imagePhoneEn: EmployeePhoneStep2En,
        title: props.block.content.steps[1]?.title,
        description: props.block.content.steps[1]?.description,
    },
    {
        image: EmployeePhoneStep3,
        imageEn: EmployeePhoneStep3En,
        imagePhone: EmployeePhoneStep3,
        imagePhoneEn: EmployeePhoneStep3En,
        title: props.block.content.steps[2]?.title,
        description: props.block.content.steps[2]?.description,
    },
    {
        image: EmployeePhoneStep4,
        imageEn: EmployeePhoneStep4En,
        imagePhone: EmployeePhoneStep4,
        imagePhoneEn: EmployeePhoneStep4En,
        title: props.block.content.steps[3]?.title,
        description: props.block.content.steps[3]?.description,
    },
]);


const getCorrectSchema = computed(() => props.isEmployer ? stepsEmployer.value : stepsEmployee.value);

const props = defineProps({
    block: Object,
    isEmployer: Boolean,
    isBtest: Boolean,
});

let stepIndex = ref(0);
</script>

<template>
    <span id='howitworks' class='how-it-works'></span>
    <div
        :class="isEmployer ? 'bg-white lg:bg-blue-medium text-blue lg:text-white' :'bg-gradient-to-r from-pink to-orange'"
        class=''>

        <div class='container py-10 md:py-18'>

            <h2
                class='text-2xl font-semibold uppercase sm:text-2xl md:text-3xl lg:text-4xl mb-8 md:mb-15 text-center'
            >
                <span v-html='block.content.title'>
                </span>

            </h2>

            <div v-if='getCorrectSchema' :class="isBtest ? 'flex-col lg:flex-row' : ''"
                 class='relative flex'>


                <div v-for='(step, key) in getCorrectSchema'
                     :key='step.title'
                     :class="(key === stepIndex) ? '' : (isBtest) ? (isEmployer) ? 'lg:hidden' : '' : 'hidden'"
                     class='grow w-full'>

                    <div class='flex gap-5 sm:gap-10 w-full justify-between' :class="((!isBtest) && (!isEmployer)) ? 'xl:gap-30' : null">
                        <div class='flex items-center'>
                            <button :class="[(isBtest) ? (isEmployer) ? 'hidden lg:flex' : 'hidden' : '',
                (isEmployer) ? 'bg-pink hover:bg-orange-500' : 'border-2 hover:border-blue-medium hover:text-blue-medium'
                ]" class='min-h-10 min-w-10 p-1 rounded-full text-white'
                                    @click="(stepIndex > 0 ) ? stepIndex = stepIndex -1 : ''">
                                <ArrowLeftIcon></ArrowLeftIcon>
                            </button>
                        </div>
                        <div class='grow'>

                            <Img v-if='!((!isBtest) && (!isEmployer))'
                                 :classes="'w-full hidden lg:block'"
                                 :image='step.imageEn'
                                 :image-NL='step.image'
                                 alt='app step image'
                            />
                            <div v-else>

                                <div class='hidden lg:flex gap-12'>
                                    <div class='w-1/3'>
                                        <Img
                                            :image='step.imageEn'
                                            :image-NL='step.image'
                                            alt='app step image'
                                        />
                                    </div>


                                    <div class='w-2/3 flex flex-col justify-center'>
                                        <div>
                                            <div class='text-2xl font-semibold uppercase sm:text-2xl md:text-3xl lg:text-5xl mb-8'
                                                 v-html='step.title'
                                            >

                                            </div>
                                            <div class='font-medium text-xl' v-html='step.description'>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <Img
                                :classes="'w-full lg:hidden sm:px-30'"
                                :image='step.imagePhoneEn'
                                :image-NL='step.imagePhone'
                                alt='app step image'
                            />
                        </div>

                        <div class='flex items-center'>
                            <button :class="[(isBtest) ? (isEmployer) ? 'hidden lg:flex' : 'hidden' : '',
                (isEmployer) ? 'bg-pink hover:bg-orange-500' : 'border-2 hover:border-blue-medium hover:text-blue-medium'
                ]" class='min-h-10 min-w-10 p-1 rounded-full text-white '
                                    @click="(stepIndex < getCorrectSchema.length -1 ) ? stepIndex = stepIndex +1 : ''">
                                <ArrowRightIcon></ArrowRightIcon>
                            </button>
                        </div>
                    </div>
                    <div :class="(!isBtest) && (!isEmployer) ? 'lg:hidden' : ''" class='mb-8 mt-4 xl:mt-12 font-bold text-center text-3xl'
                         v-html='step.title'>
                    </div>

                </div>


            </div>
            <div :class="(isBtest) ? (isEmployer) ? 'hidden lg:flex' : 'hidden' : ''" class='flex justify-center'>


                <div class='flex gap-4'>
                    <template v-for='(step, key) in getCorrectSchema'>
                        <button
                            :class="key === stepIndex ? (isEmployer) ? 'bg-pink hover:!bg-pink' : 'bg-white' : 'border-2'"
                            class='min-h-6 min-w-6 rounded-full hover:bg-white'
                            @click='stepIndex = key'></button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.how-it-works {
    display: block;
    height: 20vh;
    margin-top: -20vh;
    visibility: hidden;
}
</style>
