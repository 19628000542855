<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core';
import { ref } from 'vue';

// Components
import CounterItems from '@/Components/Counter/CounterItems.vue';

const counterItemsComponent = ref(null);
const isCounterVisible = useElementVisibility(counterItemsComponent);
</script>

<template>
    <div class="container pt-8 md:pt-16">
        <CounterItems :animationPaused="isCounterVisible" />
    </div>
</template>
