<script setup>
// Icons
import starFull from '@i/star-full-pink.svg';
import starEmpty from '@i/star-empty-pink.svg';

// Components
import Icon from '@/Components/Icon.vue';

const props = defineProps({
    stars: String,
});

const totalStars = 5;
const emptyStars = totalStars - parseInt(props.stars);
</script>

<template>
    <div class="flex" v-if="stars">
        <div v-for="i in parseInt(stars)" :key="i">
            <Icon :icon="starFull" class="w-5 h-5" alt="full star icon" />
        </div>
        <div v-for="i in emptyStars" :key="i">
            <Icon :icon="starEmpty" class="w-5 h-5" alt="full star icon" />
        </div>
    </div>
</template>
