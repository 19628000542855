<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import Button from '@/Components/Button/Button.vue';

const submittedForm = ref(false);
const { t } = useI18n();

const form = useForm({
    name: '',
    email: '',
});
const route = inject('route');
const sendForm = () => {
    form.post(route('form.newsletter'), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            submittedForm.value = true;
        },
        onError: (errors) => {
            form.errors = mapErrors(errors, (error) => [error]);
        },
    });
};
</script>

<template>
    <div class="container flex justify-center my-16">
        <div class="w-full md:w-8/12">
            <h2 class="mb-6 text-[36px] font-bold leading-9 uppercase md:text-5xl" v-if="!submittedForm">
                {{ t('Stay informed? Leave your details') }}
            </h2>
            <div v-if="submittedForm">
                <h2 class="mb-6 text-[36px] font-bold leading-9 uppercase md:text-5xl">
                    {{ t('Thank you for your interest in flexable') }}
                </h2>
                <p class="">
                    {{ t("We'll keep you updated via email!") }}
                </p>
            </div>
            <FormKit type="form" id="newsletter-form" @submit="sendForm" :actions="false" v-if="!submittedForm">
                <div class="flex flex-col items-start md:gap-8 md:flex-row">
                    <FormKit
                        type="text"
                        name="name"
                        :label="$t('Name *')"
                        v-model="form.name"
                        :errors="form.errors.name"
                        validation="required|name"
                        :value="form.name"
                        validate="required"
                        :classes="{
                            label: 'text-white',
                        }"
                    />

                    <FormKit
                        type="email"
                        name="email"
                        :label="$t('E-mail *')"
                        validation="required|email"
                        :errors="form.errors.email"
                        v-model="form.email"
                        :classes="{
                            label: 'text-white',
                        }"
                    />

                    <div class="flex justify-start">
                        <Button
                            admin
                            :processing="form.processing"
                            :recentlySuccessful="form.recentlySuccessful"
                            :text="$t('Send')"
                            class="w-auto mt-[27px] min-h-[42px]"
                        />
                    </div>
                </div>
            </FormKit>
        </div>
    </div>
</template>
