<script setup>
import { inject } from 'vue';
import BlogArticle from './BlogArticle.vue';

const route = inject('route');

const props = defineProps({
    blogArticles: {
        type: Array,
        required: true,
    },
    extraPaddingBottom: Boolean,
});
</script>

<template>
    <div
        class="py-15 md:pt-18 bg-blue-medium"
        :class="{ 'md:pb-55': extraPaddingBottom }"
        v-if="blogArticles && blogArticles.length"
    >
        <div class="container justify-center md:flex">
            <div>
                <h2 class="text-lg md:text-[36px] uppercase font-semibold mb-8 md:mb-6">{{ $t('Blog') }}</h2>

                <div class="grid gap-8 mb-6 md:grid-cols-3">
                    <template v-for="blog in blogArticles" :key="blog.id">
                        <BlogArticle :blog="blog" />
                    </template>
                </div>

                <Link
                    :href="route('blogs.index')"
                    class="float-right text-base uppercase transition-colors duration-300 hover:text-pink"
                    >{{ $t('All blogs') }}</Link
                >
            </div>
        </div>
    </div>
</template>
