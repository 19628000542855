<script setup lang="ts">
// Components

defineProps({
    countTo: {
        type: Number,
        required: true,
    },
    customOutput: {
        type: String,
        required: false,
    },
    durationCounter: Number,
    delayCounter: Number,
    label: String,
    animationPaused: Boolean,
    icon: String,
    unit: String,
    format: {
        type: Function,
        default: (number: number) => {
            return number.toFixed(0);
        },
    },
});

const numberClasses =
    'text-[80px] md:text-[80px] leading-18 font-bold bg-clip-text bg-gradient-to-r from-pink to-orange text-transparent text-center';
</script>

<template>
    <div class="flex flex-col items-center justify-start gap-y-2 md:justify-center align-items-start">
        <div class="flex gap-2 min-h-20">
            <number
                v-if="countTo && !customOutput"
                :from="0"
                :to="countTo"
                :duration="durationCounter"
                :delay="delayCounter"
                :animationPaused="animationPaused"
                easing="Power1.easeOut"
                :format="format"
                :class="numberClasses"
            />
            <span v-else :class="numberClasses">{{ customOutput }}</span>
        </div>

        <span
            class="min-w-[150px] mt-2 text-xs font-bold text-center uppercase md:text-base"
            v-if="label"
            v-html="label"
        ></span>
    </div>
</template>
