<script setup>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';

// Icons
import ReviewCard from './ReviewCard.vue';

// Components

const { t } = useI18n();
const route = inject('route');

const props = defineProps({
    builder: Array,
    media: Object,
    title: String,
});
</script>

<template>
    <div class="bg-white text-blue" v-if="builder && builder.length">
        <div class="container py-20 md:py-30">
            <h2
                class="mb-12 text-3xl font-bold leading-8 uppercase md:text-5xl md:leading-12"
                v-if="title"
                v-html="title"
            ></h2>
            <div v-for="(block, i) in builder" :key="i">
                <div class="md:flex md:flex-col md:items-center">
                    <div class="md:w-8/12">
                        <ReviewCard :review="block.data" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
