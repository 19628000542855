<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import Form from './Form.vue';
import UspList from './UspList.vue';

const { t } = useI18n();

const listItems = computed(() => [
    { text: t('Qualified professionals') },
    { text: t('Work with the best Fl@xr’s') },
    { text: t('Flexible and quick scaling up and down') },
    { text: t('Reliable') },
    { text: t('Use full potential/growth with flexible staffing') },
    { text: t('Transparent hourly wages & costs') },
    { text: t('No obligations and no administrative hassle') },
    { text: t('Invoicing and payment settled by us') },
]);
</script>

<template>
    <span class="sign-up-app" id="signup"></span>
    <div class="py-10 md:py-18 xl:px-20 2xl:px-40 bg-white text-blue">
        <div class="container">
            <div class="grid gap-8 md:grid-cols-2">
                <Form />
                <UspList :title="$t('Our advantages')" :listItems="listItems" />
            </div>
        </div>
    </div>
</template>

<style>
.sign-up-app {
    display: block;
    height: 20vh;
    margin-top: -20vh;
    visibility: hidden;
}
</style>
