<script setup>
import Button from '@/Components/Button/Button.vue';
import { mapErrors } from '@/utilities';
import { useGtm } from '@gtm-support/vue-gtm';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

const route = inject('route');
const gtm = useGtm();

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
});

const sendForm = () => {
    form.get(route('register'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
        onSuccess:() => {
            gtm.trackEvent({
                event: 'form_account_employers',
            });
        }
    });
};
</script>

<template>
    <div class="test">
        <h1
            class="text-2xl font-semibold uppercase sm:text-2xl lg:text-3xl xl:text-4xl mb-4"
            v-html="
                $t('Create an account without obligation')
            "
        ></h1>
        <FormKit type="form" id="signup-form" @submit="sendForm" :actions="false">
            <FormKit
                type="text"
                name="first_name"
                :label="$t('First Name')"
                v-model="form.first_name"
                :errors="form.errors.first_name"
                :value="form.first_name"
                :label-class="{
                    'text-blue': false,
                }"
                validation="required"
            />
            <FormKit
                type="text"
                name="last_name"
                :label="$t('Last Name')"
                v-model="form.last_name"
                :errors="form.errors.last_name"
                :value="form.last_name"
                :label-class="{
                    'text-blue': false,
                }"
                validation="required"
            />
            <FormKit
                type="email"
                name="email"
                :label="$t('E-mail')"
                v-model="form.email"
                :label-class="{
                    'text-blue': false,
                }"
                validation="required|email"
            />
            <Button
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                :text="$t('Send')"
                class="w-auto mt-6"
            />
        </FormKit>
    </div>
</template>
